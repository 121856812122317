﻿/*.filetype-pdf, .filetype-word, .filetype-excel, .filetype-powerpoint, .filetype-text, .filetype-image, .filetype-sound, .filetype-video, .filetype-archive{
	background: url('/Content/images/filetypes.png') no-repeat;
}*/

.filetype-abstract {
    background-repeat: no-repeat;
    background-size: 40px;
    /*height: 100%;
    width: 10%;*/
}

.filetype-pdf       {@extend .filetype-abstract; 	    background-image: url('/Content/images/filetypes/pdf.png') ;}
.filetype-word      {@extend .filetype-abstract; 	    background-image: url('/Content/images/filetypes/word.png');}
.filetype-excel     {@extend .filetype-abstract;	    background-image: url('/Content/images/filetypes/excel.png');}
.filetype-powerpoint{@extend .filetype-abstract;    	background-image: url('/Content/images/filetypes/powerpoint.png');}
.filetype-text      {@extend .filetype-abstract;     	background-image: url('/Content/images/filetypes/text.png');}
.filetype-image     {@extend .filetype-abstract;    	background-image: url('/Content/images/filetypes/image.png');}
.filetype-sound     {@extend .filetype-abstract;    	background-image: url('/Content/images/filetypes/sound.png');}
.filetype-video     {@extend .filetype-abstract;        background-image: url('/Content/images/filetypes/video.png');}
.filetype-archive   {@extend .filetype-abstract;        background-image: url('/Content/images/filetypes/archive.png');}
.filetype-folder    {@extend .filetype-abstract;        background-image: url('/Content/images/filetypes/folder3.png');}
.filetype-folder2   {@extend .filetype-abstract;        background-image: url('/Content/images/filetypes/folder2.png');}
.filetype-other     {@extend .filetype-abstract;        background-image: url('/Content/images/filetypes/file.png');}

