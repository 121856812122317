﻿@font-face {
  font-family: 'Candara';
  src: url('/Content/fonts/Candara.eot');
  src: url('/Content/fonts/Candara.eot?#iefix') format('embedded-opentype'),
       url('/Content/fonts/Candara.woff') format('woff'),
       url('/Content/fonts/Candara.ttf') format('truetype'),
       url('/Content/fonts/Candara.svg#Candara') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Candara';
  src: url('/Content/fonts/Candarab.eot');
  src: url('/Content/fonts/Candarab.eot?#iefix') format('embedded-opentype'),
       url('/Content/fonts/Candarab.woff') format('woff'),
       url('/Content/fonts/Candarab.ttf') format('truetype'),
       url('/Content/fonts/Candarab.svg#Candara-Bold') format('svg');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Candara';
  src: url('/Content/fonts/Candarai.eot');
  src: url('/Content/fonts/Candarai.eot?#iefix') format('embedded-opentype'),
       url('/Content/fonts/Candarai.woff') format('woff'),
       url('/Content/fonts/Candarai.ttf') format('truetype'),
       url('/Content/fonts/Candarai.svg#Candara-Italic') format('svg');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Candara';
  src: url('/Content/fonts/Candaraz.eot');
  src: url('/Content/fonts/Candaraz.eot?#iefix') format('embedded-opentype'),
       url('/Content/fonts/Candaraz.woff') format('woff'),
       url('/Content/fonts/Candaraz.ttf') format('truetype'),
       url('/Content/fonts/Candaraz.svg#Candara-BoldItalic') format('svg');
  font-weight: bold;
  font-style: italic;
}

