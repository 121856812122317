﻿@import "variables";

.conversations {

    .conversation-new, .conversation-active, .conversation-inactive {

    }

    .conversation-new {

    }

    .conversation-active {

    }

    .conversation-inactive {

    }
}

.conversation {
    margin-top: 8em;
    .me, .you, .send {
        //width: 80%;
        border-radius: 5px;
        padding: 1em 2em 2em 1em;
        margin-bottom: 1em;

        .status {
            text-align: right;
            position: relative;
            top: 2em;
            right: -1em;
            color: $gray;

            .glyphicon {
                margin-left: 1em;
            }
        }

        div {
            padding: 0;
        }
    }

    .me {
        margin-left: 20%;
        background-color: $brand-warning-lighter;
        border: 1px solid $brand-warning;
    }

    .you {
        margin-right: 20%;
        background-color: $brand-info-lighter;
        border: 1px solid $brand-info;
    }




} 