﻿@import "variables";

.btn {
    -ms-text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
}

.btn-generic {
    margin-top: 3px;
    margin-bottom: 0;
    border: none;
    display: flex;
    padding: 5px 5px 5px 0;
    cursor: pointer;
    text-overflow: ellipsis;
    overflow: hidden;

    .content { // Content
        -ms-text-overflow: ellipsis;
        -o-text-overflow: ellipsis;
        text-overflow: ellipsis;
        overflow: hidden;
        &:active, &.active {
            @include box-shadow(none);
        }

        text-decoration: none;
        width: 100%; 
        font-size: 1.4em;
        margin-left: 10px;
    }

    &.list {
        width: 100%;
        clear: both;
        align-items: stretch;

        .icon {
            width: 50px;
            position: relative;

            div {
                width: 40px;
                height: 40px;
                top: 50%;
                left: 50%;
                -moz-transform: translate(-50%, -50%);
                -ms-transform: translate(-50%, -50%);
                -o-transform: translate(-50%, -50%);
                -webkit-transform: translate(-50%, -50%);
                transform: translate(-50%, -50%);
                position: absolute;

                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }
        }
        .content {

        }

    }

    &.box {
        width: 160px;
        height: 130px;
        float: left;
        clear: none;
        margin-right: 9px;
        margin-bottom: 10px;
        flex-direction: column;
        align-content: flex-start;
        padding: 0;

        .icon {
            width:100%;
            height: 80px;
            margin: 0;
            div {
                margin: auto;
                width: 100%;
                height: 100%;
                background-position: center;

                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
            }

        }
        .content {
            margin-top: 5px;
            font-size: 1.1em;
        }

    }




}

.btn-folder {
    @extend .btn-generic;
    min-height: 5em;
    height: 5em;
    @include button-variant($brand-primary, $brand-primary-superlight, transparent);
    border-left: 5px solid $brand-primary;
    font-weight: bold;

    &:hover {
        border-left: 5px solid $brand-warning;
    }
}


.btn-file {
    @extend .btn-generic;
    min-height: 5em;
    @include button-variant($brand-primary, white, $gray-darker);
    border-left: 5px solid white;

    &:hover {
        border-left: 5px solid $brand-info;
    }

    .content { // Content
        line-height: 1.0em;
        .text-muted {
            font-size: 0.8em;
        }
    }

}


.btn-title {
    @extend .btn-generic;
    border-bottom: 1px solid $brand-primary;
    border-left: 5px solid white;
    align-items: center;
    .icon {
        margin-left: 3px;
        width: 55px;
    }
    .content { // Content
        font-size: 3em;
        line-height: 1.2em;
    }

}

