﻿@import "variables";
@import "variablesBlue";

@media (min-width: 992px) {
    // colored strip in the background
    #menu-left {
        display: table-cell;
        vertical-align: top;
        width: 150px;
        background-color: $brand-primary;
    }
    #menu-right {
        display: table-cell;
        vertical-align: top;
        width: 180px;
        background-color: $gray-lighter;
    }

    #content-center {
        display: table-cell;
        vertical-align: top;

    }

}

@media (max-width: 991px) {
    #menu-left-content {
        top: 45px;
        .logo {
            display: none;
        }
        li.blank4 {
            display: none;
        }
    }

}

#menu-left-content {
    z-index: 999;
    width: 150px;
    min-width: 150px;
    background-color: $brand-primary;
    overflow: hidden;
    .logo {
        text-align: center;
        height: 165px;
        background-color: white;
        padding-top: 20px;
    }

    ul {
        border-width: 0;
        background-color: transparent;
        background-image: none;
        li.k-item { // Default li
            height: 3em;
            a {
                color: white;
                height: 3em;
                line-height: 3em;
                background-color: $brand-primary-light;
                border-color: $brand-primary;
                background-image: none;

                &:hover {
                    background-color: $brand-primary-lighter;
                    span.glyphicon {
                        color: $brand-warning;
                    }
                }

                span.glyphicon {
                    font-size: 1.3em;
                    top: 0.15em;
                    margin-right: 1em;
                }
            }
            a.k-state-selected { // selected!
                background-color: $brand-primary-lighter;
                box-shadow: none;
                color: $brand-warning;
                span.glyphicon {
                    color: $brand-warning;
                }
            }

        }
        li.blank1 { // empty li with 1 height
            height: 3em;
            background-color: transparent;
            span.k-link {
                background-color: transparent;
                border: none;
                padding: 0;
            }
        }
        li.blank4 { // empty li with 4 height
            height: 12em;
            background-color: transparent;
            /*border-bottom: 1px solid $brand-warning;*/
            span.k-link {
                background-color: transparent;
                border: none;
                padding: 0;
            }
        }

        li.k-state-highlight { // currently active li
            border-bottom: 1px solid $brand-warning;
            a {
                border-bottom: 0;
            }

            &:after {
                content: "";
                background-color: $brand-warning;
                width: 5px;
                height: 3em;
                display: block;
                position: relative;
                top: -3.0em;
            }

        }



    }

    // =====================================
    // ANIMATION
    // =====================================
    .k-item .animated {
        -webkit-animation-duration: 1s;
        animation-duration: 1s;
        -webkit-animation-fill-mode: both;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-direction: alternate;
    }
    @-webkit-keyframes pulse {
        0% { background-color: $brand-primary-light; }
        100% { background-color: $brand-warning; }
    }
    @keyframes pulse {
        0% { background-color: $brand-primary-light; }
        100% { background-color: $brand-warning; }
    }
    .k-item .pulse {
        -webkit-animation-name: pulse;
        animation-name: pulse;
    }

    .donutLabel {
        width: 50px;
        height: 50px;
        position: absolute;
        color: white;
        text-align: center;
        line-height: 50px;
        font-size: 20px;
        font-family: $font-family-base;
        margin-top: 26px;
        margin-left: 9px;
    }

    .languages {
        margin-top: 3em;
        margin-left: 1.5em;

    }

    fast-link {
        padding: 1em;
        color: white;
        a {
            width: 100%;
            color: $brand-info;
            margin-bottom: 1em;
            display: block;
            text-decoration: none;
            &:before {
                content: "";
                display: inline-block;
                width: 10px;
                height: 10px;
                margin-right: 5px;
                background-color: $brand-warning;
            }
            &:hover {
                color: $brand-warning;
            }

        }
    }

}

#menu-right-content {
    width: 140px; 
    min-width: 140px;
    margin: 20px;
    .k-content {
        background-color: inherit;
    }
}