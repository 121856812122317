﻿@import "variables";
@import "variablesBlue";
/*Bootstrap Override*/

.panel-info, .panel-warning, .panel-danger, .panel-success, .panel-primary {
    border: none;
}

.panel-info {
    background-color: $state-info-bg;
}

.panel-danger {
    background-color: $state-danger-bg;
}

.panel-warning {
    background-color: $state-warning-bg;
}

.panel-primary {
    background-color: lighten($brand-primary, 60%);
}

.panel-success{
    background-color: $state-success-bg;
}

.panel-default{
    .panel-heading {
        border: none;
    }
}

.alert {
    //font-size: 14px;
}

.breadcrumb {
    font-weight: normal;
    text-transform: none;
}


@media (min-width: 768px) {
.navbar > .container .navbar-brand, .navbar > .container-fluid .navbar-brand {
    margin-left: 0;
}
}

/* DESIGN */
/* ====== */
.clickable {
    cursor: pointer;
} 

.nopadding-left {
    padding-left: 0;
}
 .nopadding-right {
    padding-right: 0;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0; 
}

.strong {
    font-weight: bold;
}

.large {
    font-size: 200%;
    line-height: 10px;
}

.line-danger {
    border-left: 5px solid $brand-danger;
    padding-left: 1.5em;
}

/*KENDO OVERWRITE FOR HIGHLIGHTED ROWS IN A GRID*/ 
.k-grid tr.bg-primary:hover {
    background-color: $brand-primary-light;
}

/*KENDO OVERWRITE FOR Expand/Collapse Buttons on Grids*/
.k-grid .k-hierarchy-cell .k-icon.k-plus {
    background-image: none;
    overflow:visible;
    outline-width: 0;
    text-decoration: none;
    @extend .glyphicon;
    @extend .glyphicon-zoom-in;
    &:before {
        font-size: 20px;
        color: green;
    }
}

.k-grid .k-hierarchy-cell .k-icon.k-minus {
    background-image: none;
    overflow:visible;
    outline-width: 0;
    text-decoration: none;
    @extend .glyphicon;
    @extend .glyphicon-zoom-out;
    &:before {
        font-size: 20px;
        color: red;
    }
}

.k-tooltip {
    background-color: $state-info-bg !important;
    max-width: 300px;
    border: 1px solid $brand-info !important;
    font-size: larger;
}


