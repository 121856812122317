﻿@import "variables";

$room-width: 190px;
$subroom-scale: 0px;
$base-margin: 15px;

.roomList {
    .dataRoom {
        margin-right: $base-margin;
        max-width: $room-width;
    }
}

.dataRoom {
    float: left;
    .roomTitle {
        margin: 10px 0;
    }
    .roomDescription {
        display: none;
    }
}

.dataRoomListItem {
    max-height: 122px;
    .roomImage {
        float: left;
        display: block;
        width: $room-width;
    }
    .roomTitle {
        margin: 0;
    }
    .roomDescription {
        margin-left: $room-width*2.1;
        margin-top: -1.5em;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

.dataRoom, .dataRoomListItem {
    width: 100%;
    border: 1px solid $btn-default-border;
    border-radius: 5px;
    background-color: white;
    padding: 10px;
    overflow: hidden;
    margin-bottom: $base-margin;

    .roomImage {
        height: 100px;
        border-radius: 5px;
        background-size: contain;
        background-repeat:no-repeat;
        background-position: center;
    }

    /*&.subRoom {
        opacity: 0.7;
        margin-top: @subroom-scale;
        margin-right: @subroom-scale + @base-margin;
        max-width: @room-width - @subroom-scale;

        .roomImage {
            height: 100px - @subroom-scale;
            background-size: contain;
        }
    }*/

    .roomTitle {
        font-size: 1.5em;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .roomIcons {
        text-align: left;
        width: 100%;

        .icon {
            @extend .clickable;
            margin-right: 8%;
            background-color: transparent;
            border: none;
            font-size: 1.5em;
            color: $gray;

            a:link, a:visited, a:active {
                color: $gray;
            }

            a:hover {
                color: $brand-warning;
            }
        }

        .icon:first-child {
            margin-left: 0;
        }

        .icon.pull-right {
            margin-right: 0;
            @extend .btn;
            @extend .btn-default;
            background-color: $gray-lighter;
            box-shadow: none;
            border-color: $brand-primary;
            font-size: 1.4em;
            margin-top: -0.4em;
        }

        .icon.active {
            background-color: $brand-warning !important;
        }

    }
}

.bg-warning .dataRoom {
    background-color: $state-warning-bg;
}

.bg-danger .dataRoom {
    background-color: $state-danger-bg;
}

//Used in FolderDesignerTemplate in Image Picker
.roomImages {
    .roomImage {
        float: left;
        position: relative;
        width: 111px;
        height: 170px;
        margin: 0 5px;
        padding: 0;

        div {
            width: 110px;
            height: 110px;
            background-size:contain;
            background-repeat:no-repeat;
            background-position:center;
            //cursor: pointer;
        }

        h3 {
            margin: 0;
            padding: 3px 5px 0 0;
            max-width: 96px;
            overflow: hidden;
            line-height: 1.1em;
            font-size: .9em;
            font-weight: normal;
            text-transform: uppercase;
            color: $gray;
        }

        p {
            visibility: hidden;
            text-transform: uppercase;
        }

        &:hover p {
            visibility: visible;
            position: absolute;
            width: 110px;
            height: 110px;
            top: 0;
            margin: 0;
            padding: 0;
            line-height: 110px;
            vertical-align: middle;
            text-align: center;
            color: #fff;
            background-color: rgba(0,0,0,0.75);
            -moz-transition: background .2s linear, color .2s linear;
            -o-transition: background .2s linear, color .2s linear;
            -webkit-transition: background .2s linear, color .2s linear;
            transition: background .2s linear, color .2s linear;
            cursor: pointer;
        }
    }
}

