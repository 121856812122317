﻿@import "variables";
/* APP LAYOUT*/
/* ==========*/

html {
    height: 100%;

    body {
        min-height: 100%;
        #css2js,  #xsrfContainer {
            display: none;
        }


        .brand-background {
            width: 100%;
            height: 100%;
            position: fixed;
            top: 0;
            left: 0;
            background-size: cover;
            z-index: -1;
        }

        #mainframe {
            overflow: hidden;
            background-color: white;

            .container-fluid {
                padding: 0;
            }

            #body-content {
                position: relative;

                .navbar {
                    margin-bottom: 0;
                }

                .navbar-header {
                    margin-left: 0;
                    width: 100%;
                }
            }


            .brand-banner {
                height: 27em;
                min-width: 200px;
                text-align: center;
                background-position-y: 0;
                background-repeat: no-repeat;
                margin-bottom: 10px;

                .brand-logo {
                    text-align: right;
                    img {
                        background-color: rgba(255, 255, 255, 0.7);
                        height: 154px;
                    }
                }

                .alert {
                    padding: 5px;
                    margin-bottom: 0;
                }

            }

            #layout-content, #content-fullscreen {
                    margin-bottom: 2em;
                    .folderAction, .fileAction {
                        display: block;
                        position: fixed;
                        z-index: 100;
                        background-color: white;
                        margin: 1em;
                        padding: 1em;
                        border: 1px solid $brand-primary;
                        width: 60%;
                        max-width: 400px;
                        min-width: 300px;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        border-radius: 10px;
                        box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.3);
                        h3 {
                            color: $brand-warning;
                            margin-top: 0;
                            &.header {
                                margin-right: 25px;
                            }
                            &.close {
                                position: absolute;
                                right: 0;
                                top: 5px;
                            }
                        }
                        a {
                            text-align: left;
                        }
                        .glyphicon {
                            margin-right: 10px;
                            font-size: 1.5em;
                            top: 0.2em;
                        }

                    }

                    .background {
                        position: fixed;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        background-color: black;
                        opacity: 0.5;
                        z-index: 99;
                    }


                h3 {
                    margin-top: 0;
                }
                .row {
                    overflow: visible;
                }
            }

            .breadcrumb {
                font-size: 12px;
            }
            .nav-buttons .btn {
                margin-bottom: 10px;
                 @include button-variant($brand-primary, $gray-lighter, transparent);
            }
        }
    }
}

/*Encancements for Large Layouts = background-image in frame*/
@media (min-width: 1801px) {
    html {
        width: 100%;
        body {
            background-size: cover;
            width: 100%;
            padding-left: 100px;
            padding-right: 100px;

            #mainframe { 
                width: 100%;
                margin-left: 0;
                margin-right: 0;
                margin-top: 30px;
                margin-bottom: 30px;
                border: 1px solid $brand-primary;
                border-radius: 0px;
                box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.3);

                /*border: 5px solid lime;*/

                #content-center {
                    width: 1000px;
                    #layout-content {
                        //margin-left: 150px;
                        //margin-right: 180px;
                        padding-left: 10px;
                        padding-right: 10px;
                    }

                }
                #content-fullscreen {
                    display: table-cell;
                    background-color: silver;
                    padding-left: 20px; 
                    padding-right: 20px;
                }
            }
        }
    }
}

@media (min-width: 992px) and (max-width: 1800px) {
    html {
        body {
            background-size: cover;

            #mainframe { 
                width: 90%;
                margin-left: auto;
                margin-right: auto;
                margin-top: 30px;
                margin-bottom: 30px;
                border: 1px solid $brand-primary;
                border-radius: 0px;
                box-shadow: 0 0 30px 10px rgba(0, 0, 0, 0.3);
                
                max-width: 1170px;
                /*border: 5px solid fuchsia;*/

                #layout-content {
                    //margin-left: 150px;
                    //margin-right: 180px;
                    padding-left: 10px;
                    padding-right: 10px;
                }

                #content-fullscreen {
                    display: none;
                }
            }
        }
    }
}

@media (max-width: 991px) {
    html {
        body {
            .brand-background {
                display: none;
            }

            #mainframe {
                .brand-banner {
                    height: 14em;
                }

                #layout-content {
                    padding-left: 10px;
                    padding-right: 10px;

                }
                #content-fullscreen {
                    display: none;
                }
            }
        }
    }
}


language-switcher {
    padding: 10px;
    height: 45px;
    float: right;
    img {
        width: 24px;
        cursor: pointer;
    }

}