﻿@import "variables";

$room-width: 190px;
$base-margin: 15px;


.roomInfo {
    width: 100%;
    //border: 1px solid @btn-default-border;
    border-radius: 5px;
    background-color: white;
    padding: 10px;
    overflow: hidden;
    margin-bottom: $base-margin;
    //float: left;


    .roomImage {
        height: 140px;
        border-radius: 5px;
        //background-color: @gray;
        background-size: contain;
        background-repeat:no-repeat;
        background-position: left;
        margin-bottom: 20px;
    }


    .roomIcons {
        text-align: left;
        width: 100%;
        margin-top: 10px;

        .icon {
            @extend .clickable;
            margin-right: 8%;
            background-color: transparent;
            border: none;
            font-size: 1.5em;
            color: $gray;

            a:link, a:visited, a:active {
                color: $gray;
            }

            a:hover {
                color: $brand-warning;
            }
        }

        .icon:first-child {
            margin-left: 0;
        }

        .icon.pull-right {
            margin-right: 0;
            //.btn();
            //.btn-default();
            //.button-variant(@brand-primary; @gray-lighter; transparent);
            //background-color: @gray-lighter;
            //box-shadow: none;
            //border-color: @brand-primary;
            //font-size: 1.4em;
            //margin-top: -0.4em;
            font-size: 2em;
            span.glyphicon {
                color: $brand-warning;
            }
        }

        .icon.active {
            background-color: $brand-warning !important;
        }

    }

    .publicShareInfo {
        border: 1px solid $brand-primary;
        padding: 10px;
    }
}



