﻿//== Colors
//
//## Gray and brand colors for use across Bootstrap.

$gray-base:              #000 !default;
$gray-darker:            lighten($gray-base, 13.5%) !default; // #222
$gray-dark:              lighten($gray-base, 20%) !default;   // #333
$gray:                   #6f6f6f !default; // #555
$gray-light:             lighten($gray-base, 60%) !default;   // #999
$gray-lighter:           lighten($gray-base, 93.5%) !default; // #eee

$brand-primary:             rgb(44,49,68) !default;
$brand-primary-light:       lighten($brand-primary, 15%);
$brand-primary-lighter:     lighten($brand-primary, 25%);
$brand-primary-superlight:  lighten($brand-primary, 65%);

$brand-success:             rgb(101,191,103) !default;
$brand-success-lighter:     lighten($brand-success, 20%);

$brand-info:                rgb(121,216,255) !default;
$brand-info-lighter:        lighten($brand-info, 20%);

$brand-warning:             rgb(255,140,86) !default;
$brand-warning-lighter:    lighten($brand-warning, 20%);

$brand-danger:              #F04124 !default;

//== Scaffolding
//
//## Settings for some of the most global styles.

//** Background color for `<body>`.
$body-bg:               #fff !default;
//** Global text color on `<body>`.
$text-color:            $gray-darker !default;


//== Typography
//
//## Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif:  "Candara", "Open Sans", "Helvetica Neue", Helvetica, Arial, sans-serif !default;
$font-size-base:          11px !default;
$font-size-small:         10px !default;


$btn-default-bg:                 #eaeaea !default;
$input-border-focus:             $brand-primary-lighter !default;
$breadcrumb-bg:                 white !default;
$border-radius-base:        0 !default;

//== Alerts
//
//## Define alert colors, border radius, and padding.

$alert-padding:               15px !default;
$alert-border-radius:         $border-radius-base !default;
$alert-link-font-weight:      normal !default;

$alert-success-bg:            lighten($brand-success, 20%) !default;
$alert-success-text:          $text-color !default;
$alert-success-border:        $brand-success !default;

$alert-info-bg:               lighten($brand-info, 20%) !default;
$alert-info-text:             $text-color !default;
$alert-info-border:           $brand-info !default;

$alert-warning-bg:            lighten($brand-warning, 20%) !default;
$alert-warning-text:          $text-color !default;
$alert-warning-border:        $brand-warning !default;

$alert-danger-bg:             lighten($brand-danger, 20%) !default;
$alert-danger-text:           $text-color !default;
$alert-danger-border:         $brand-danger !default;
